<template>
  <v-card>
    <v-row class="ma-0 h-full">
      <v-col cols="12" sm="6" class="pa-0">
        <v-card-title v-t="'candidatesRejectReasons'" class="align-start font-weight-semibold" />

        <v-card-text class="mt-4">
          <vue-apex-charts
            id="chart-sales-overview"
            type="donut"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="6" class="chart-reject-reasons-overview-col pa-0">
        <v-card-title>
          <span v-t="'topReasons'" class="me-3" />
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(rejectReason, index) in mostUsedRejectReasons.labels"
              :key="index"
              :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3' : ''}`"
            >
              <div class="d-flex align-center flex-grow-1">
                <div class="me-2">
                  <v-list-item-title class="text-sm font-weight-semibold text-pre-wrap">
                    <div :class="`stats-dot ${chartColorsNames[index]} d-inline-block rounded-circle me-2`"></div>
                    {{ rejectReason }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-xs text-pre-wrap">
                    {{ mostUsedRejectReasons.categories[index] }}
                  </v-list-item-subtitle>
                </div>

                <v-spacer></v-spacer>

                <div class="d-flex align-center">
                  <h4 class="text-base font-weight-semibold me-1">
                    {{ mostUsedRejectReasons.data[index] }}
                  </h4>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiCurrencyUsd } from '@mdi/js'
import { useState } from 'vuex-composition-helpers'
import { getVuetify, addAlpha } from '@core/utils'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  setup() {
    const { recruitment } = useState('dashboard', ['recruitment'])

    const $vuetify = getVuetify()

    const chartColorsNames = ['primary', 'success', 'accent', 'info', 'secondary']

    const mostUsedRejectReasons = {
      labels: recruitment.value.countedRejectReasons.labels.slice(0, 5),
      data: recruitment.value.countedRejectReasons.data.slice(0, 5),
      categories: recruitment.value.countedRejectReasons.categories.slice(0, 5),
    }

    const chartOptions = {
      legend: {
        show: false,
      },
      colors: [
        $vuetify.theme.currentTheme.primary,
        addAlpha($vuetify.theme.currentTheme.success, 0.7),
        addAlpha($vuetify.theme.currentTheme.accent, 0.7),
        addAlpha($vuetify.theme.currentTheme.info, 0.8),
        addAlpha($vuetify.theme.currentTheme.secondary, 0.5),
      ],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,
              },
              total: {
                show: true,
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                },
              },
            },
          },
        },
      },
      labels: recruitment.value.countedRejectReasons.labels,
    }

    const chartData = recruitment.value.countedRejectReasons.data

    return {
      countedRejectReasons: recruitment.value.countedRejectReasons,
      chartColorsNames,
      mostUsedRejectReasons,
      chartOptions,
      chartData,
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .chart-reject-reasons-overview-col {
    @include ltr() {
      border-left: thin solid;
    }
    @include rtl() {
      border-right: thin solid;
    }
  }
}
@include theme--child(chart-reject-reasons-overview-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}
</style>
