<template>
  <v-card class="d-flex flex-column">
    <v-card-title v-t="'talentPoolStats'" class="align-start mb-8 font-weight-semibold" />
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12" md="5" class="pa-0">
          <div class="pa-3">
            <p class="text-5xl font-weight-semibold text--primary mb-2">
              {{ allValidEntries }}
            </p>
            <span v-t="'allNonAnonymizedEntries'" class="text-base" />
          </div>
        </v-col>
        <v-col cols="12" md="7" class="d-flex align-center">
          <v-row>
            <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="6" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation(data.title).color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation(data.title).icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  {{ $t(data.title) }}
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ data.total }}
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiRobotOffOutline, mdiShredder, mdiFileDocumentEditOutline, mdiAccountMultipleCheckOutline } from '@mdi/js'
import { useState } from 'vuex-composition-helpers'

export default {
  setup() {
    const { talentPool } = useState('dashboard', ['talentPool'])

    const statisticsData = [
      {
        title: 'valid',
        total: talentPool.value.validCount,
      },
      {
        title: 'expired',
        total: talentPool.value.expiredCount,
      },
      {
        title: 'anonymizationRequested',
        total: talentPool.value.anonymizationRequestCount,
      },
      {
        title: 'autoAnonymizationDisabled',
        total: talentPool.value.autoAnonymizationDisabledCount,
      },
    ]

    const resolveStatisticsIconVariation = (data) => {
      if (data === 'valid') return { icon: mdiAccountMultipleCheckOutline, color: 'success' }
      if (data === 'expired') return { icon: mdiFileDocumentEditOutline, color: 'error' }
      if (data === 'anonymizationRequested') return { icon: mdiShredder, color: 'warning' }
      if (data === 'autoAnonymizationDisabled') return { icon: mdiRobotOffOutline, color: 'secondary' }

      return { icon: mdiShredder, color: 'warning' }
    }

    return {
      allValidEntries: talentPool.value.allValidEntries,
      statisticsData,
      resolveStatisticsIconVariation,
    }
  },
}
</script>
