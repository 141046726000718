<template>
  <v-card>
    <v-card-title v-t="'projectWarnings'" class="align-start flex-nowrap font-weight-semibold" />

    <v-card-text class="min-height--project-at-risk">
      <v-list class="pt-0 mt-n1">
        <v-list-item
          v-for="(project, index) in projectsAtRisk"
          :key="project.id"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3' : ''}`"
        >
          <div class="d-flex align-center flex-grow-1 flex-nowrap">
            <div class="me-2">
              <v-list-item-title class="text-sm font-weight-semibold text-pre-wrap">
                <router-link
                  :to="{ name: 'apps-project-view', params: { id: project.id } }"
                  class="font-weight-semibold cursor-pointer text-decoration-none"
                >
                  <div class="stats-dot error d-inline-block rounded-circle me-2"></div>
                  {{ project.name }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle class="text-xs text-pre-wrap">
                {{ project.clientName }}
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex align-center flex-shrink-0">
              <h4 class="text-base font-weight-semibold me-1">
                {{ project.takenAssignees }}/{{ project.demandCounter }} ({{ project.workload }}%)
              </h4>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { useState } from 'vuex-composition-helpers'

export default {
  setup() {
    const { project } = useState('dashboard', ['project'])

    return {
      projectsAtRisk: project.value.projectsAtRisk,
    }
  },
}
</script>

<style lang="scss" scoped>
.stats-dot {
  opacity: 1;
}
@import 'src/styles/mixins.scss';
.min-height--project-at-risk {
  @include set-min-height-overflow($height: 700px);
}
</style>
