<template>
  <v-card>
    <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1">
        <v-card-title v-t="'accommodationUsage'" class="align-start flex-nowrap font-weight-semibold" />

        <v-card-text>
          <vue-apex-charts
            id="total-profit-chart"
            :height="$vuetify.breakpoint.smAndUp ? 120 : 140"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd, mdiChartBar } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { useState } from 'vuex-composition-helpers'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const $vuetify = getVuetify()

    const { house } = useState('dashboard', ['house'])

    const statisticsData = [
      {
        name: vm.$t('occupiedByEmployees'),
        data: house.value.takenSeatsByEmployee,
        color: 'primary',
      },
      {
        name: vm.$t('occupiedByOthers'),
        data: house.value.takenSeatsByWho,
        color: 'success',
      },
      {
        name: vm.$t('available'),
        data: house.value.free,
        color: 'secondary',
      },
    ]

    const chartOptions = {
      colors: [
        $vuetify.theme.currentTheme.primary,
        $vuetify.theme.currentTheme.secondary,
        $vuetify.theme.currentTheme.success,
      ],
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 6,
          columnWidth: '35%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: ['House filling'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: (val) => `${val}%`,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        strokeDashArray: 7,
        padding: {
          right: 0,
          left: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 6,
        lineCap: 'round',
        colors: ['#fff'],
      },
    }

    const chartData = [
      {
        name: vm.$t('occupiedByEmployees'),
        data: [house.value.takenSeatsByEmployee],
      },
      {
        name: vm.$t('occupiedByOthers'),
        data: [house.value.takenSeatsByWho],
      },
      {
        name: vm.$t('available'),
        data: [house.value.free],
      },
    ]

    return {
      statisticsData,
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
