<template>
  <v-card>
    <v-card-title class="align-start flex-nowrap font-weight-semibold">
      {{ $t(chartTitle) }}
      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <!-- Chart -->
    <v-card-text>
      <vue-apex-charts height="200" :options="chartOptions" :series="chartData"></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp } from '@mdi/js'

import { getVuetify } from '@core/utils'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    chartTitle: {
      type: String,
      default: '',
    },
    chartDataName: {
      type: String,
      default: 'Counted statuses',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const $vuetify = getVuetify()

    const chartOptions = {
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          left: 30,
        },
      },
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      markers: {
        size: 4,
        hover: {
          sizeOffset: 3,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.4,
          stops: [0, 100, 100],
        },
      },
      stroke: {
        width: 5,
        curve: 'smooth',
        lineCap: 'round',
      },
      colors: [$vuetify.theme.currentTheme.primary],
      legend: {
        show: false,
      },
      xaxis: {
        categories: props.labels,
        labels: {
          offsetY: 3,
          formatter: (value) => `${formatDate(value, { day: 'numeric', month: 'numeric' })}`,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter(val) {
            return val.toFixed(0)
          },
        },
        min: 0,
      },

      // tooltip: {
      //   enabled: true,
      //   enabledOnSeries: true,
      //   followCursor: false,
      //   onDatasetHover: {
      //     highlightDataSeries: true,
      //   },
      // },
    }

    const chartData = [
      {
        name: props.chartDataName,
        data: props.data,
      },
    ]

    return {
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiChevronUp,
      },
    }
  },
}
</script>
