<template>
  <v-card>
    <v-card-title v-t="'projectEmployment'" class="align-start font-weight-semibold" />

    <v-card-text class="min-height-status-bar">
      <vue-apex-charts
        :options="chartOptions"
        :series="chartData"
        :height="calculateChartHeight"
        type="bar"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { useState } from 'vuex-composition-helpers'
import { getVuetify } from '@core/utils'
import { computed } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartColor: {
      type: String,
      default: '#3b3559',
    },
    projectsAmount: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const calculateChartHeight = computed(() => {
      if (props.projectsAmount !== null) return 200 + props.projectsAmount * 30

      return 200
    })

    const $vuetify = getVuetify()

    const { project } = useState('dashboard', ['project'])

    const chartOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 6,
          startingShape: 'rounded',
          endingShape: 'rounded',
          columnWidth: '15%',
          colors: {
            backgroundBarRadius: 10,
          },
        },
      },
      colors: [$vuetify.theme.currentTheme.info],
      dataLabels: {
        position: 'top',
        offsetX: 40,
        formatter(val, opt) {
          const { goals } = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]

          if (goals && goals.length) {
            return `${val} / ${goals[0].value}`
          }

          return val
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Inter", sans-serif',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          top: -20,
          bottom: -10,
          right: 30,
          left: -20,
        },
      },
    }

    const chartData = [
      {
        name: 'Actual',

        data: project.value.projectEmployment.map((proj) => {
          return {
            x: proj.name,
            y: proj.allAssignees,
            goals: [{ name: 'Demand', value: proj.demand, strokeColor: $vuetify.theme.currentTheme.primary }],
          }
        }),
      },
    ]

    return {
      calculateChartHeight,

      chartOptions,
      chartData,
    }
  },
}
</script>
<style lang="scss" scoped>
.v-application.theme--dark .apexcharts-canvas line {
  stroke: var(--v-primary-base) !important;
}
@import 'src/styles/mixins.scss';
.min-height-status-bar {
  @include set-min-height-overflow($height: 400px);
}
</style>
