<template>
  <v-card class="d-flex flex-column">
    <v-card-title v-t="'candidatesStats'" class="align-start mb-8 font-weight-semibold">
      <!--      <v-spacer></v-spacer>-->
      <!--      <v-btn icon small class="me-n3 mt-n2">-->
      <!--        <v-icon>-->
      <!--          {{ icons.mdiDotsVertical }}-->
      <!--        </v-icon>-->
      <!--      </v-btn>-->
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="4" class="d-flex align-center">
          <v-avatar size="44" :color="resolveStatisticsIconVariation(data.title).color" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ resolveStatisticsIconVariation(data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t(data.title) }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCogOutline, mdiThumbDownOutline, mdiThumbUpOutline } from '@mdi/js'
import { useState } from 'vuex-composition-helpers'

export default {
  setup() {
    const { recruitment } = useState('dashboard', ['recruitment'])

    const statisticsData = [
      {
        title: 'inProgress',
        total: recruitment.value.inProgressCount,
      },
      {
        title: 'hired',
        total: recruitment.value.hiredCount,
      },
      {
        title: 'rejected',
        total: recruitment.value.rejectedCount,
      },
    ]

    const resolveStatisticsIconVariation = (data) => {
      if (data === 'inProgress') return { icon: mdiCogOutline, color: 'primary' }
      if (data === 'hired') return { icon: mdiThumbUpOutline, color: 'success' }
      if (data === 'rejected') return { icon: mdiThumbDownOutline, color: 'error' }

      return { icon: mdiCogOutline, color: 'primary' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,
    }
  },
}
</script>
