<template>
  <v-card>
    <v-card-title v-t="'employeeStats'" class="align-start font-weight-semibold" />

    <v-card-text>
      <vue-apex-charts :options="chartOptions" :series="chartData" height="350" type="bar"></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { getVuetify } from '@core/utils'
import { useState } from 'vuex-composition-helpers'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const $vuetify = getVuetify()

    const { employee } = useState('dashboard', ['employee'])

    const chartOptions = {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      colors: [$vuetify.theme.currentTheme.primary],
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 6,
          startingShape: 'rounded',
          endingShape: 'rounded',
          columnWidth: '15%',
          colors: {
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          bottom: -10,
          right: 30,
          left: -20,
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Inter", sans-serif',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
      },
      fill: {
        opacity: 1,
      },
      labels: employee.value.countedStatuses.labels,
      tooltip: {
        custom({ series, seriesIndex, dataPointIndex, w }) {
          return (
            `${'<div class="arrow_box pa-2">' + '<span>'}${w.globals.labels[dataPointIndex]}: ${
              series[seriesIndex][dataPointIndex]
            }</span>` + `</div>`
          )
        },

        // x: {
        //   show: true,
        // },
        // y: {
        //   title: {
        //     formatter() {
        //       return ''
        //     },
        //   },
        // },
      },
    }

    const chartData = [
      {
        data: employee.value.countedStatuses.data,
      },
    ]

    return {
      chartOptions,
      chartData,
    }
  },
}
</script>
