<template>
  <v-card>
    <v-card-title class="align-start font-weight-semibold">
      {{ $t('newUnhandledCandidates') }}
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-list class="pt-0 mt-n1">
        <v-list-item
          v-for="(data, index) in waitingCandidates"
          :key="data.avatar"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3' : ''}`"
          :to="{
            name: 'apps-ats-candidate-view',
            params: { jobId: data.jobId, candidateId: data.candidate.id },
          }"
        >
          <v-list-item-avatar size="38">
            <v-avatar color="primary v-avatar-light-bg primary--text" size="32">
              <span class="font-weight-medium">{{
                avatarText(`${data.candidate.user.firstName} ${data.candidate.user.lastName}`)
              }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <div class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
            <div>
              <v-list-item-title class="text-sm font-weight-medium">
                {{ `${data.candidate.user.firstName} ${data.candidate.user.lastName}` }}
              </v-list-item-title>
              <div class="d-flex align-center">
                <v-list-item-subtitle class="text-xs">
                  {{ data.jobTitle }}
                </v-list-item-subtitle>
              </div>
            </div>

            <v-spacer></v-spacer>

            <v-chip label small color="warning" class="v-chip-light-bg text-sm font-weight-semibold warning--text">
              {{ $t('noActionForXDays', { daysInStep: data.daysInStep }) }}
            </v-chip>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCalendarBlankOutline, mdiDotsVertical } from '@mdi/js'
import { useState } from 'vuex-composition-helpers'
import { avatarText } from '@core/utils/filter'

export default {
  setup() {
    const { recruitment } = useState('dashboard', ['recruitment'])

    return {
      waitingCandidates: recruitment.value.waitingCandidates,
      avatarText,
      icons: {
        mdiDotsVertical,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>
