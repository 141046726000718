<template>
  <v-row class="match-height">
    <v-col v-if="$can(ACTIONS.UPDATE, PRIVATE.DASHBOARD)" cols="12" md="6">
      <v-autocomplete
        v-model="recruiters"
        :placeholder="$t('allRecruiters')"
        :items="adminsAndRecruiters"
        outlined
        dense
        clearable
        multiple
        hide-details
        @change="refetchRecruitmentsData"
      />
    </v-col>
    <v-col v-if="$can(ACTIONS.UPDATE, PRIVATE.DASHBOARD)" cols="12" md="6">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        @input="
          refetchRecruitmentsData()
          refetchEmployeesData()
        "
      >
        <template v-slot:activator="activator">
          <v-text-field
            :value="formattedDate"
            :label="$t('dateRange')"
            readonly
            outlined
            dense
            v-bind="activator.attrs"
            hide-details
            v-on="activator.on"
          />
        </template>
        <v-date-picker
          v-model="dateRange"
          :first-day-of-week="1"
          :locale="$i18n.locale"
          range
          :selected-items-text="$t('selected')"
          @input="orderDateRange"
        />
      </v-menu>
    </v-col>

    <v-col cols="12" md="4">
      <recruitment-waiting-candidates-list v-if="recruitmentSuccessFetched" />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" md="8">
      <v-row class="match-height">
        <v-col cols="12" md="8">
          <recruitment-statistics-card v-if="recruitmentSuccessFetched" />
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="4">
          <statistics-card-with-images
            v-if="recruitmentSuccessFetched"
            :avatar="recruitmentNewCandidatesStatistic.avatar"
            :avatar-width="recruitmentNewCandidatesStatistic.avatarWidth"
            :change="recruitmentNewCandidatesStatistic.change"
            :chip-color="recruitmentNewCandidatesStatistic.chipColor"
            :chip-text="dateRangeChipText"
            :statistics="recruitmentNewCandidatesStatistic.statistics"
            :stat-title="recruitmentNewCandidatesStatistic.statTitle"
          />
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="match-height">
        <v-col cols="12">
          <new-in30-days
            v-if="recruitmentSuccessFetched"
            :chart-title="recruitmentNewIn30Days.chartTitle"
            :labels="recruitmentNewIn30Days.labels"
            :data="recruitmentNewIn30Days.data"
            :chart-data-name="recruitmentNewIn30Days.chartDataName"
          />
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="6">
      <recruitment-source-overview v-if="recruitmentSuccessFetched" />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" md="6">
      <recruitment-reject-reasons-overview v-if="recruitmentSuccessFetched" />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" :md="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES) ? 6 : 12">
      <talent-pool-statistics-card v-if="talentPoolSuccessFetched" />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>

    <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES)" cols="12" md="6">
      <new-in30-days
        v-if="employeeSuccessFetched"
        :chart-title="employeeNewIn30Days.chartTitle"
        :labels="employeeNewIn30Days.labels"
        :data="employeeNewIn30Days.data"
        :chart-data-name="employeeNewIn30Days.chartDataName"
      />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>

    <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES)" cols="12" md="8">
      <employee-status-overview v-if="employeeSuccessFetched" />
      <!--      <status-bar-chart-->
      <!--        v-if="employeeSuccessFetched"-->
      <!--        :chart-title="employeeStatusBarChartData.chartTitle"-->
      <!--        :labels="employeeStatusBarChartData.labels"-->
      <!--        :data="employeeStatusBarChartData.data"-->
      <!--        :chart-data-name="employeeStatusBarChartData.chartDataName"-->
      <!--      />-->
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>
    <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES)" cols="12" md="4">
      <v-row class="match-height">
        <v-col cols="12">
          <statistics-card-vertical
            v-if="employeeSuccessFetched"
            :change="employeeTotalEmployeesStatistic.change"
            :color="employeeTotalEmployeesStatistic.color"
            :icon="employeeTotalEmployeesStatistic.icon"
            :statistics="employeeTotalEmployeesStatistic.statistics"
            :stat-title="employeeTotalEmployeesStatistic.statTitle"
            :subtitle="employeeTotalEmployeesStatistic.subtitle"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <statistics-card-vertical
            v-if="employeeSuccessFetched"
            :change="employeeTotalEmployeesWoStatusStatistic.change"
            :color="employeeTotalEmployeesWoStatusStatistic.color"
            :icon="employeeTotalEmployeesWoStatusStatistic.icon"
            :statistics="employeeTotalEmployeesWoStatusStatistic.statistics"
            :stat-title="employeeTotalEmployeesWoStatusStatistic.statTitle"
            :subtitle="employeeTotalEmployeesWoStatusStatistic.subtitle"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.HOUSES)" cols="12" md="4">
      <house-statistics-card v-if="houseSuccessFetched" />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>

    <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.HOUSES)" cols="12" md="8">
      <house-filling-bar-chart v-if="houseSuccessFetched" />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" md="8">
      <v-row class="match-height">
        <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.CLIENTS)" cols="6" md="3">
          <statistics-card-vertical
            v-if="clientSuccessFetched"
            :change="clientTotalStatistic.change"
            :color="clientTotalStatistic.color"
            :icon="clientTotalStatistic.icon"
            :statistics="clientTotalStatistic.statistics"
            :stat-title="clientTotalStatistic.statTitle"
            :subtitle="clientTotalStatistic.subtitle"
          ></statistics-card-vertical>
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.PROJECTS)" cols="6" md="3">
          <statistics-card-vertical
            v-if="projectSuccessFetched"
            :change="projectTotalPositionStatistic.change"
            :color="projectTotalPositionStatistic.color"
            :icon="projectTotalPositionStatistic.icon"
            :statistics="projectTotalPositionStatistic.statistics"
            :stat-title="projectTotalPositionStatistic.statTitle"
            :subtitle="projectTotalPositionStatistic.subtitle"
          ></statistics-card-vertical>
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.CLIENTS)" cols="6" md="3">
          <statistics-card-vertical
            v-if="clientSuccessFetched"
            :change="clientProjectTotalStatistic.change"
            :color="clientProjectTotalStatistic.color"
            :icon="clientProjectTotalStatistic.icon"
            :statistics="clientProjectTotalStatistic.statistics"
            :stat-title="clientProjectTotalStatistic.statTitle"
            :subtitle="clientProjectTotalStatistic.subtitle"
          ></statistics-card-vertical>
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
        <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.CLIENTS)" cols="6" md="3">
          <statistics-card-radial-bar-chart
            v-if="clientSuccessFetched"
            :stat-title="clientWorkloadRadialChart.statTitle"
            :statistics="clientWorkloadRadialChart.statistics"
            :chart-series="clientWorkloadRadialChart.series"
            :chart-color="$vuetify.theme.currentTheme.info"
          ></statistics-card-radial-bar-chart>
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.PROJECTS)" cols="12">
          <status-bar-chart-test v-if="projectSuccessFetched" :projects-amount="projectAmount" />
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="card-heading, list-item-two-line, list-item-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.PROJECTS)" cols="12" md="4">
      <project-at-risk-list v-if="projectSuccessFetched" />
      <v-skeleton-loader
        v-else
        v-bind="attrs"
        type="card-heading, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import StatisticsCardRadialBarChart from '@core/components/statistics-card/StatisticsCardRadialBarChart.vue'

import { useActions, useState } from 'vuex-composition-helpers'
import { onMounted, ref, computed, getCurrentInstance } from '@vue/composition-api'
import { mdiHandshakeOutline, mdiBookOutline, mdiToolboxOutline } from '@mdi/js'
import { getVuetify } from '@core/utils'
import moment from 'moment'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

import RecruitmentStatisticsCard from './recruitment/RecruitmentStatisticsCard.vue'
import RecruitmentSourceOverview from './recruitment/RecruitmentSourceOverview.vue'
import RecruitmentRejectReasonsOverview from './recruitment/RecruitmentRejectReasonsOverview.vue'
import RecruitmentWaitingCandidatesList from './recruitment/RecruitmentWaitingCandidatesList.vue'

import TalentPoolStatisticsCard from './talent-pool/TalentPoolStatisticsCard.vue'

import EmployeeStatusOverview from './employee/employeeStatusOverview.vue'

import HouseStatisticsCard from './house/HouseStatisticsCard.vue'
import HouseFillingBarChart from './house/HouseFillingBarChart.vue'

import ProjectAtRiskList from './project/ProjectAtRiskList.vue'

import StatusBarChartTest from './utils/StatusBarChartTest.vue'
import NewIn30Days from './utils/NewIn30Days.vue'
import jobsUtils from '@/utils/jobsUtils'

export default {
  components: {
    StatisticsCardRadialBarChart,
    StatisticsCardWithImages,
    StatisticsCardVertical,

    RecruitmentStatisticsCard,
    RecruitmentSourceOverview,
    RecruitmentRejectReasonsOverview,
    RecruitmentWaitingCandidatesList,

    StatusBarChartTest,
    NewIn30Days,

    EmployeeStatusOverview,

    HouseStatisticsCard,
    HouseFillingBarChart,

    ProjectAtRiskList,

    TalentPoolStatisticsCard,
  },
  setup() {
    const $vuetify = getVuetify()
    const vm = getCurrentInstance().proxy

    const recruiters = ref([])
    const menu = ref(false)
    const dateRange = ref([moment().subtract(30, 'days').format('yyyy-MM-DD'), moment().format('yyyy-MM-DD')])

    const formattedDate = computed(() => {
      return dateRange.value.map((date) => moment(date).format('dddd, Do MMMM YYYY')).join(' - ')
    })
    const orderDateRange = () => {
      return dateRange.value.sort((a, b) => new Date(a) - new Date(b))
    }

    const recruitmentSuccessFetched = ref(false)
    const talentPoolSuccessFetched = ref(false)
    const employeeSuccessFetched = ref(false)
    const houseSuccessFetched = ref(false)
    const projectSuccessFetched = ref(false)
    const clientSuccessFetched = ref(false)

    const projectAmount = ref(0)

    const { recruitment, employee, house, project, client } = useState('dashboard', [
      'recruitment',
      'employee',
      'house',
      'project',
      'client',
    ])
    const {
      getDashboardRecruitmentData,
      getDashboardTalentPoolData,
      getDashboardEmployeeData,
      getDashboardHouseData,
      getDashboardProjectData,
      getDashboardClientData,
    } = useActions('dashboard', [
      'getDashboardRecruitmentData',
      'getDashboardTalentPoolData',
      'getDashboardEmployeeData',
      'getDashboardHouseData',
      'getDashboardProjectData',
      'getDashboardClientData',
    ])

    const { fetchRecruitersChoices, recruiterChoices } = jobsUtils()
    const adminsAndRecruiters = computed(() => {
      const assignedToAdmins = [{ header: 'Admin' }]
      const assignedToRecruiters = [{ divider: true }, { header: 'Recruiter' }]

      recruiterChoices.value
        .filter((user) => !['expert', 'suspended'].includes(user.access))
        .forEach((user) => {
          if (user.access === 'admin') {
            assignedToAdmins.push({
              text: `${user.user.firstName} ${user.user.lastName}`,
              value: user.id,
            })
          } else if (user.access === 'recruiter') {
            assignedToRecruiters.push({
              text: `${user.user.firstName} ${user.user.lastName}`,
              value: user.id,
            })
          }
        })

      return [...assignedToAdmins, ...assignedToRecruiters]
    })

    const recruitmentNewCandidatesStatistic = {
      statTitle: 'newCandidates',
      statistics: '0',
      chipColor: 'primary',
      avatar: require('@/assets/images/avatars/10.png'),
      avatarWidth: '86',
    }
    const recruitmentNewIn30Days = {
      chartTitle: 'incomingCandidates',
      chartDataName: 'newCandidates',
      labels: [],
      data: [],
    }

    const employeeStatusBarChartData = {
      chartTitle: 'employeeStats',
      chartDataName: 'employeeStats',
      labels: [],
      data: [],
    }
    const employeeNewIn30Days = {
      chartTitle: 'newEmployees',
      chartDataName: 'newEmployees',
      labels: [],
      data: [],
    }
    const employeeTotalEmployeesStatistic = {
      statTitle: 'totalEmployees',
      icon: mdiToolboxOutline,
      color: 'info',
      subtitle: 'sumAllEmployees',
      statistics: '0',
    }
    const employeeTotalEmployeesWoStatusStatistic = {
      statTitle: 'employeesWithoutStatus',
      icon: mdiToolboxOutline,
      color: 'info',
      subtitle: 'sumAllEmployeesWithoutStatus',
      statistics: '0',
    }

    const houseStatusBarChartData = {
      chartTitle: 'houseStatusOverview',
      chartDataName: 'houseCountedStatuses',
      labels: [],
      data: [],
      chartColor: $vuetify.theme.currentTheme.primary,
    }

    const projectStatusBarChartData = {
      chartTitle: 'projectStatusOverview',
      chartDataName: 'projectCountedStatuses',
      labels: [],
      data: [],
    }
    const projectTotalPositionStatistic = {
      statTitle: 'totalPositions',
      icon: mdiToolboxOutline,
      color: 'info',
      subtitle: 'sumForAllPositionsSubheader',
      statistics: '0',
    }

    const clientStatusBarChartData = {
      chartTitle: 'clientCountedStatuses',
      chartDataName: 'clientStatusOverview',
      labels: [],
      data: [],
    }
    const clientWorkloadRadialChart = {
      statTitle: 'levelOfEmployment',
      statistics: '-/-',
      series: [],
    }
    const clientTotalStatistic = {
      statTitle: 'totalClients',
      icon: mdiHandshakeOutline,
      color: 'info',
      statistics: '0',
    }
    const clientProjectTotalStatistic = {
      statTitle: 'totalProjects',
      icon: mdiBookOutline,
      color: 'info',
      subtitle: 'sumForAllProjectsSubheader',
      statistics: '0',
    }

    const dateRangeChipText = ref(`${moment().subtract(30, 'days').format('DD.MM')} - ${moment().format('DD.MM')}`)
    const formattedDateRangeChipText = () => {
      if (dateRange.value.length <= 1)
        dateRangeChipText.value = `${moment().subtract(30, 'days').format('DD.MM')} - ${moment().format('DD.MM')}`

      dateRangeChipText.value = orderDateRange()
        .sort((a, b) => new Date(a) - new Date(b))
        .map((date) => moment(date).format('DD.MM'))
        .join(' - ')
    }

    const refetchRecruitmentsData = async () => {
      if (menu.value) return

      recruitmentSuccessFetched.value = false
      recruitmentSuccessFetched.value = await getDashboardRecruitmentData({
        recruiters: recruiters.value,
        dateRange: dateRange.value.length <= 1 ? undefined : dateRange.value,
      })
      recruitmentNewCandidatesStatistic.statistics = `${recruitment.value.statisticCandidatesIn30Days}`
      recruitmentNewIn30Days.labels = recruitment.value.candidatesIn30Days.labels
      recruitmentNewIn30Days.data = recruitment.value.candidatesIn30Days.data
      formattedDateRangeChipText()
    }

    const refetchEmployeesData = async () => {
      if (menu.value) return

      employeeSuccessFetched.value = false
      employeeSuccessFetched.value = await getDashboardEmployeeData({
        dateRange: dateRange.value.length <= 1 ? undefined : dateRange.value,
      })

      employeeStatusBarChartData.labels = employee.value.countedStatuses.labels
      employeeStatusBarChartData.data = employee.value.countedStatuses.data
      employeeNewIn30Days.labels = employee.value.employeesIn30Days.labels
      employeeNewIn30Days.data = employee.value.employeesIn30Days.data
      employeeTotalEmployeesStatistic.statistics = `${employee.value.allEmployeesStats}`
      employeeTotalEmployeesWoStatusStatistic.statistics = `${employee.value.allEmployeesWoStatusStats}`
    }

    onMounted(async () => {
      if (vm.$can(ACTIONS.UPDATE, PRIVATE.DASHBOARD)) await fetchRecruitersChoices()

      recruitmentSuccessFetched.value = await getDashboardRecruitmentData({
        recruiters: recruiters.value,
        dateRange: dateRange.value.length <= 1 ? undefined : dateRange.value,
      })
      recruitmentNewCandidatesStatistic.statistics = `${recruitment.value.statisticCandidatesIn30Days}`
      recruitmentNewIn30Days.labels = recruitment.value.candidatesIn30Days.labels
      recruitmentNewIn30Days.data = recruitment.value.candidatesIn30Days.data
      formattedDateRangeChipText()

      talentPoolSuccessFetched.value = await getDashboardTalentPoolData()

      if (vm.$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES)) {
        employeeSuccessFetched.value = await getDashboardEmployeeData({
          dateRange: dateRange.value.length <= 1 ? undefined : dateRange.value,
        })
        employeeStatusBarChartData.labels = employee.value.countedStatuses.labels
        employeeStatusBarChartData.data = employee.value.countedStatuses.data
        employeeNewIn30Days.labels = employee.value.employeesIn30Days.labels
        employeeNewIn30Days.data = employee.value.employeesIn30Days.data
        employeeTotalEmployeesStatistic.statistics = `${employee.value.allEmployeesStats}`
        employeeTotalEmployeesWoStatusStatistic.statistics = `${employee.value.allEmployeesWoStatusStats}`
      }

      if (vm.$can(ACTIONS.READ, PRIVATE.STAFF.HOUSES)) {
        houseSuccessFetched.value = await getDashboardHouseData()
        houseStatusBarChartData.labels = house.value.countedStatuses.labels
        houseStatusBarChartData.data = house.value.countedStatuses.data
      }

      if (vm.$can(ACTIONS.READ, PRIVATE.STAFF.PROJECTS)) {
        projectSuccessFetched.value = await getDashboardProjectData()
        projectStatusBarChartData.labels = project.value.countedStatuses.labels
        projectStatusBarChartData.data = project.value.countedStatuses.data
        projectTotalPositionStatistic.statistics = `${project.value.positionAmount}`
      }

      if (vm.$can(ACTIONS.READ, PRIVATE.STAFF.CLIENTS)) {
        clientSuccessFetched.value = await getDashboardClientData()
        clientStatusBarChartData.labels = client.value.countedStatuses.labels
        clientStatusBarChartData.data = client.value.countedStatuses.data
        clientWorkloadRadialChart.series = [client.value.workload]
        clientWorkloadRadialChart.statistics = `${client.value.assigneesCount}/${client.value.demandSum}`
        clientTotalStatistic.statistics = `${client.value.clientsAmount}`
        clientProjectTotalStatistic.statistics = `${client.value.projectsAmount}`
        projectAmount.value = client.value.projectsAmount
      }
    })

    return {
      recruiters,
      menu,
      dateRange,
      formattedDate,
      orderDateRange,
      adminsAndRecruiters,
      dateRangeChipText,

      recruitmentNewCandidatesStatistic,
      recruitmentSuccessFetched,
      recruitmentNewIn30Days,
      recruitment,
      refetchRecruitmentsData,

      talentPoolSuccessFetched,

      employeeSuccessFetched,
      employeeStatusBarChartData,
      employeeNewIn30Days,
      employeeTotalEmployeesStatistic,
      employeeTotalEmployeesWoStatusStatistic,
      employee,
      refetchEmployeesData,

      houseSuccessFetched,
      houseStatusBarChartData,

      projectSuccessFetched,
      projectStatusBarChartData,
      projectTotalPositionStatistic,

      clientSuccessFetched,
      clientStatusBarChartData,
      clientWorkloadRadialChart,
      clientTotalStatistic,
      clientProjectTotalStatistic,

      projectAmount,

      attrs: {
        // class: 'mb-6',
        boilerplate: true,
        elevation: 0,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
