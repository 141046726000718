<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="align-start mb-8">
      <span v-t="'accommodationStats'" class="font-weight-semibold" />
    </v-card-title>

    <v-card-text class="d-flex justify-center align-center h-full">
      <v-row>
        <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="4" class="d-flex align-center">
          <v-avatar size="44" :color="resolveStatisticsIconVariation(data.title).color" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ resolveStatisticsIconVariation(data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t(data.title) }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiHomeOutline, mdiDoor, mdiBedOutline } from '@mdi/js'
import { useState } from 'vuex-composition-helpers'

export default {
  setup() {
    const { house } = useState('dashboard', ['house'])

    const statisticsData = [
      {
        title: 'houses',
        total: house.value.housesCount,
      },
      {
        title: 'rooms',
        total: house.value.roomsCount,
      },
      {
        title: 'places',
        total: house.value.occupantCount,
      },
    ]

    const resolveStatisticsIconVariation = (data) => {
      if (data === 'houses') return { icon: mdiHomeOutline, color: 'primary' }
      if (data === 'rooms') return { icon: mdiDoor, color: 'info' }
      if (data === 'places') return { icon: mdiBedOutline, color: 'success' }

      return { icon: mdiHomeOutline, color: 'primary' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,
    }
  },
}
</script>
